.menu {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 50px 0 50px;
}


.menu-header {
    padding: 0 50px 0 50px;
    display: flex;
    justify-content: space-between;
}

.menu-header > * {
    display: flex;
    align-items: center;
}