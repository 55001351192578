* {
    box-sizing: border-box;
    font-family: "Lato", serif !important;
    scrollbar-color: #1D70B7 #f1f1f1;
    scrollbar-width: thin;
}

input {
    color: #52575C!important;
}

select {
    color: #676879;
    font-family: "Lato";  
    font-size:  15px ;
    font-weight: 400;
    
}
option {
    font-family: inherit;  
}

body {
    padding: 0;
    margin: 0;
    background: #F6F8FB;
}

a {
    text-decoration: none;
    color: initial;
}

.MuiButton-root {
    text-transform: none !important;
}
.MuiTableCell-root {
    border-bottom: 1px solid #E8E8E8!important;
}
.MuiDivider-root {
    background-color: #E8E8E8!important;
}
@media (max-width: 599px) {
    html, body {
        background: white;
    }
}

:focus-visible {
    outline: none;
}


/* width */
::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #1D70B7;
    border-radius: 8px;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #155892;
  }